import Splitting from "splitting";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
	/*
	----------------------
	Banner intro
	----------------------
	*/
	const lead = document.querySelector(".main-banner p");
	const btn = document.querySelector(".main-banner .act");
	const poster = document.querySelector(".main-banner .poster");

	Splitting();

	const intro = gsap.timeline({ paused: true });

	gsap.set(".main-banner .caption", { opacity: 1 });

	intro
		.from(".main-banner small .char", {
			opacity: 0,
			stagger: 0.1,
			duration: 0.25,
			ease: "Power3.easeOut",
			y: 10,
		})
		.from(
			".main-banner h1 .word",
			{
				opacity: 0,
				stagger: 0.15,
				duration: 1,
				ease: "Power3.easeOut",
				y: 10,
			},
			"-=.5"
		)
		.to(poster, { opacity: 1, duration: 2.5 }, "-=1")
		.from(lead, { opacity: 0, y: 50, ease: "Power3.easeOut" }, "-=2")
		.from(btn, { opacity: 0, y: 50, ease: "Power3.easeOut" }, "<+=.35");

	setTimeout(() => {
		intro.play();
	}, 300);

	/*
	----------------------
	Solucion animacion
	----------------------
	*/
	const st = gsap.timeline({ paused: true });

	st.from(".solucion-tangarina .solu-1", {
		opacity: 0,
		duration: 1.75,
		scale: 0,
		transformOrigin: "50% 50%",
		ease: "back.out(1.7)",
	})
		.from(
			".solucion-tangarina .circ",
			{
				opacity: 0,
				// scale: 0,
				stagger: 0.1,
				duration: 1.25,
				// transformOrigin: "50% 50%",
				ease: "Power3.easeOut",
			},
			"<"
		)
		.from(
			".solucion-tangarina .solu-2",
			{
				opacity: 0,
				y: 100,
				duration: 1,
				ease: "Power3.easeOut",
				rotate: "-50deg",
				transformOrigin: "50% 50%",
			},
			"-=.5"
		)
		.from(
			".solucion-tangarina .solu-3",
			{
				opacity: 0,
				x: -100,
				duration: 1,
				ease: "Power3.easeOut",
				rotate: "-50deg",
				transformOrigin: "50% 50%",
			},
			"-=.5"
		)
		.from(
			".solucion-tangarina .solu-4",
			{
				opacity: 0,
				y: -100,
				duration: 1,
				ease: "Power3.easeOut",
				rotate: "-50deg",
				transformOrigin: "50% 50%",
			},
			"-=.5"
		)
		.from(
			".solucion-tangarina .solu-5",
			{
				opacity: 0,
				x: 100,
				duration: 1,
				ease: "Power3.easeOut",
				rotate: "-50deg",
				transformOrigin: "50% 50%",
			},
			"-=.5"
		);

	ScrollTrigger.create({
		trigger: "#solucion",
		start: "top 50%",
		end: "bottom end",
		onEnter: () => {
			st.timeScale(1).play();
		},
		onLeaveBack: () => {
			st.timeScale(2).reverse();
		},
		// markers: true,
	});

	const spinForward = gsap.timeline({
		onComplete: function () {
			this.restart();
		},
	});
	spinForward.from(
		".solucion-tangarina .c-1, .solucion-tangarina .c-3, .solucion-tangarina .c-5, .solucion-tangarina .c-7",
		{
			rotate: "359deg",
			transformOrigin: "50% 50%",
			duration: 30,
			ease: "linear",
		}
	);

	const spinReverse = gsap.timeline({
		onComplete: function () {
			this.restart();
		},
	});
	spinReverse.from(
		".solucion-tangarina .c-2, .solucion-tangarina .c-4, .solucion-tangarina .c-6, .solucion-tangarina .c-8",
		{
			rotate: "-359deg",
			transformOrigin: "50% 50%",
			duration: 30,
			ease: "linear",
		}
	);
});
